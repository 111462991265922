<template>
  <div>
    <v-dialog
      v-model="dialog"
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      width="500"
    >
      <v-card tile>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('profile.changePass.title') }}</v-toolbar-title>
        </v-toolbar>
        <v-form ref="entryForm" @submit.prevent="onConfirm" v-model="valid">
          <v-card-text class="py-4">
            <v-text-field
              v-if="(!isChangedPasswordSocial && !isLoginSocial)"
              :rules="[ruleOldPass]"
              v-model="form.oldPass"
              :append-icon="showOldPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showOldPass ? 'text' : 'password'"
              @click:append="showOldPass = !showOldPass"
              :label="$t('profile.changePass.oldPass')"
            ></v-text-field>
            <v-text-field
              :rules="[!!form.confirmPass || $t('login.thisFieldIsRequired')]"
              v-model="form.newPass"
              :append-icon="showNewPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showNewPass ? 'text' : 'password'"
              @click:append="showNewPass = !showNewPass"
              :label="$t('profile.changePass.newPass')"
            ></v-text-field>
            <v-text-field
              :rules="[
                !!form.confirmPass || $t('login.thisFieldIsRequired'),
                form.newPass === form.confirmPass ||
                  $t('profile.changePass.passConfirmNotMatch'),
              ]"
              :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showConfirmPass ? 'text' : 'password'"
              @click:append="showConfirmPass = !showConfirmPass"
              v-model="form.confirmPass"
              :label="$t('profile.changePass.confirmPass')"
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="align-center">
            <v-btn align-center justify-center color="primary" type="submit">{{
              $t("confirmModal.title")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { isCookieEnabled, getCookie } from "tiny-cookie";
import { mapActions, mapGetters } from "vuex";
import functionUtils from "Utils/functionUtils";
export default {
  data: () => ({
    valid: false,
    form: {
      oldPass: "",
      newPass: "",
      confirmPass: "",
    },
    showOldPass: false,
    showNewPass: false,
    showConfirmPass: false,
    dialog: false,
    isChangedPasswordSocial: false,
    isLoginSocial: false
  }),
  computed: {
    ...mapGetters(['CURRENT_ACCOUNT_DATA'])
  },
  watch: {
    CURRENT_ACCOUNT_DATA () {
      this.isChangedPasswordSocial = this.CURRENT_ACCOUNT_DATA.is_changed_password_social
    }
  },
  created () {
    let isLoginSocial = sessionStorage.getItem('isLoginSocial')
    this.isLoginSocial = isLoginSocial === 'true' ? true : false
  },
  methods: {
    ruleOldPass(value) {
      if (!this.isChangedPasswordSocial && !this.isLoginSocial) {
        if (functionUtils.isNull(value) || value === "") {
          return this.$t("login.thisFieldIsRequired")
        }
      }
      return true;
    },
    onShowModal: function() {
      this.dialog = true;
    },
    onConfirm: function() {
      if (this.$refs.entryForm.validate()) {
        const userId = isCookieEnabled() ? getCookie("userId") : null;
        let filter = {
          oldPass: this.form.oldPass,
          newPass: this.form.newPass,
          isFirstChangePasswordSocial: (!this.isChangedPasswordSocial && !this.isLoginSocial)
            ? false : true
        };
        this.UPDATE_USER_PASSWORD({ id: userId, ...filter })
          .then(
            function(res) {
              this.$toast.open({
                message: this.$t("common.success"),
                type: "success",
                ...this.$toastConfig,
              });
              console.log(res.data);
              console.log(this.$store.state);
              this.$store.state.users.currentAccountData.is_changed_password_social = res.data.is_changed_password_social
              this.form.oldPass = null
              this.form.newPass = null
              this.form.confirmPass = null
              this.dialog = false
            }.bind(this)
          )
          .catch(
            function(error) {
              if (!functionUtils.isNull(error.response.data.errorText)) {
                let errorText = error.response.data.errorText;
                this.oldPassRules.push(this.$t(errorText));
                this.$refs.entryForm.validate();
              } else {
                this.$toast.open({
                  message: this.$t("common.somethingWentWrong"),
                  type: "error",
                  ...this.$toastConfig,
                });
              }
            }.bind(this)
          );
      }
    },
    ...mapActions(["UPDATE_USER_PASSWORD"]),
  },
};
</script>

<style></style>
