<template>
  <v-container
    fill-height
    fluid
    class="my-6"
    :style="`width: ${$vuetify.breakpoint.xs ? '100%' : '50%'};`"
  >
    <v-layout align-center justify-center>
      <v-flex xs12 sm12 md12>
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>{{ $t("profile.info") }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              v-model="form.fullName"
              :label="$t('register.fullName')"
            ></v-text-field>
            <v-text-field
              v-model="form.phone"
              :label="$t('profile.phone')"
            ></v-text-field>
            <v-text-field
              disabled
              v-model="form.contactEmail"
              :label="$t('common.email')"
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="justify-center align-center">
            <v-btn
              align-center
              justify-center
              color="primary"
              @click="onUpdateUserProfile"
              >{{ $t("profile.btn.update") }}</v-btn
            >
            <v-btn
              align-center
              justify-center
              color="warning"
              @click="onShowModalUpdatePassword"
              >{{ $t("profile.btn.changePass") }}</v-btn
            >
          </v-card-actions>
          <div style="padding: 8px">
            <div class="justify-center align-center" style="flex-wrap: wrap; display: flex;">
              <div v-if="ENABLE_BINDING_GOOGLE" class="col-sm-12 col-md-6 col-lg-6" style="padding: 5px">
                <a
                  v-if="googleSocialNetworkData"
                  class="btn btn-google btn-block btn-social"
                  disabled>
                  <img src="@/assets/Google-Button.svg" style="border-right: none; margin-left: 1px;"/>{{ ellipsisLongText(googleSocialNetworkData.full_name, 10) }}
                </a>
              <!-- </div>
              <div class="col-sm-12 col-md-6 col-lg-6" style="padding: 5px"> -->
                <google-button-component
                  v-else
                  :data-width="147"
                  :data-text="'signup_with'"
                  @onEmitData="googleBtnEmitData"
                />
              </div>
              <!-- <div class="col-sm-12 col-md-6 col-lg-6" style="padding: 5px">
                <a
                  v-if="ENABLE_BINDING_FACEBOOK"
                  class="btn btn-primary btn-block btn-social"
                  @click="onClickLoginFacebook">
                  <i class="fab fa-facebook"/> {{ $t('common.loginWithFacebook') }}
                </a>
              </div> -->
            </div>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <updatePasswordModal ref="updatePasswordModal" />
    <confirmModal
      ref="confirmModal"
      :title="confirmModalTitle"
      @confirm="confirmAddSocialNetwork"
    />
  </v-container>
</template>

<script>
import { isCookieEnabled, getCookie } from "tiny-cookie";
import { mapActions } from "vuex";
import functionUtils from "Utils/functionUtils";
import UpdatePasswordModal from "./UpdatePasswordModal.vue";
import SocialNetworkType from 'Enum/socialNetworkType'
import ConfirmModal from "Components/ConfirmModal";
import GoogleButtonComponent from 'Components/GoogleButtonComponent'
export default {
  components: {
    UpdatePasswordModal,
    GoogleButtonComponent,
    ConfirmModal
  },
  data() {
    return {
      loading: false,
      form: {
        fullName: "",
        contactEmail: "",
        phone: null,
      },
      googleSocialNetworkData: null,
      facebookSocialNetworkData: null,
      ENABLE_BINDING_GOOGLE: process.env.VUE_APP_ENABLE_BINDING_GOOGLE.trim() === 'true',
      ENABLE_BINDING_FACEBOOK: process.env.VUE_APP_ENABLE_BINDING_FACEBOOK.trim() === 'true',
      confirmModalTitle: '',
      isConfirmAddSocialNetwork: false
    };
  },
  mounted() {
    const userId = isCookieEnabled() ? getCookie("userId") : null;
    this.GET_USER_PROFILE({ id: userId }).then(
      function(res) {
        let data = res.data;
        this.form.fullName = functionUtils.concatFullname(data.last_name, data.first_name)
        this.form.phone = data.phone;
        this.form.contactEmail = data.email;
      }.bind(this)
    );
    this.getSocialNetwork()
  },
  methods: {
    /**
     * ellipsis Long Text
     */
    ellipsisLongText: function (value, len) {
      return functionUtils.ellipsisLongText(value, len)
    },
    /**
     * Show modal update password
     */
    onShowModalUpdatePassword: function() {
      this.$refs.updatePasswordModal.onShowModal();
    },
    /**
     * Update user profile
     */
    onUpdateUserProfile: function() {
      const userId = isCookieEnabled() ? getCookie("userId") : null;
      let filter = {
        fullName: this.form.fullName,
        phone: this.form.phone,
      };
      this.UPDATE_USER_PROFILE({ id: userId, ...filter }).then(
        function() {
          this.$toast.open({
            message: this.$t("common.success"),
            type: "success",
            ...this.$toastConfig,
          });
          const userId = isCookieEnabled() ? getCookie("userId") : null;
          this.GET_CURRENT_ACCOUNT({ id: userId });
        }.bind(this)
      );
    },
    getSocialNetwork: function () {
      this.GET_SOCIAL_NETWORK().then(
        function (res) {
          let dataList = res.data
          let googleSocialNetworkIndex = dataList.findIndex(x => x.social_type === SocialNetworkType.GOOGLE)
          this.googleSocialNetworkData = googleSocialNetworkIndex !== -1 ? dataList[googleSocialNetworkIndex] : null
          let facebookSocialNetworkIndex = dataList.findIndex(x => x.social_type === SocialNetworkType.FACEBOOK)
          this.facebookSocialNetworkData = facebookSocialNetworkIndex !== -1 ? dataList[facebookSocialNetworkIndex] : null
        }.bind(this)
      ).catch(
        function () {}
      )
    },
    googleBtnEmitData: function (data) {
      this.postGoogleLogin(data)
      this.socialNetworkSelect = data
    },
    onClickLoginGoogle: function () {
      this.$gAuth
        .signIn()
        .then(GoogleUser => {
          // on success do something
          var profile = GoogleUser.getBasicProfile();
          if (Object.keys(profile).length > 0) {
            let userProfile = {
              id: profile.getId(),
              fullName: profile.getName(),
              avatarUrl: profile.getImageUrl(),
              email: profile.getEmail()
            }
            this.postGoogleLogin(userProfile)
            this.socialNetworkSelect = userProfile
          }
        })
        // .catch(error => {
        //   console.log('error', error)
        // })
    },
    postGoogleLogin: function (userInfo) {
      let googleSocialNetwork = SocialNetworkType.GOOGLE
      this.socialNetworkTypeSelect = googleSocialNetwork
      let data = {
        socialNetworkType: googleSocialNetwork,
        fullName: userInfo && userInfo.fullName,
        socialId: userInfo && userInfo.id,
        email: userInfo && userInfo.email,
        avatarUrl: userInfo && userInfo.avatarUrl,
        isConfirmAddSocialNetwork: this.isConfirmAddSocialNetwork
      }
      this.SOCIAL_NETWORK_LOGIN(data).then(
        function () {
          this.getSocialNetwork()
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.$toast.clear()
            this.$toast.open({
              message: this.$t(errorText),
              type: "error",
              ...this.$toastConfig,
            });
          } else if (!functionUtils.isNull(error.response.data.isShowModalConfirm)) {
            this.onShowModalConfirmAddSocialNetwork()
          }
        }.bind(this)
      )
    },
    onShowModalConfirmAddSocialNetwork: function () {
      this.$refs.confirmModal.onShowModal();
      this.confirmModalTitle = this.$t('socialNetwork.notiAddSocialAccountLink',
        { '0': this.socialNetworkTypeSelect === SocialNetworkType.GOOGLE ? 'google' : 'facebook' })
    },
    confirmAddSocialNetwork: function () {
      this.isConfirmAddSocialNetwork = true
      if (this.socialNetworkTypeSelect === SocialNetworkType.GOOGLE) {
        this.postGoogleLogin(this.socialNetworkSelect)
      } else if (this.socialNetworkTypeSelect === SocialNetworkType.FACEBOOK) {
        this.postFacebookLogin(this.socialNetworkSelect)
      }
    },
    ...mapActions([
      "GET_USER_PROFILE",
      "UPDATE_USER_PROFILE",
      "GET_CURRENT_ACCOUNT",
      "SOCIAL_NETWORK_LOGIN",
      "GET_SOCIAL_NETWORK"
    ]),
  },
};
</script>

<style lang="scss" scoped>
.btn-social {
  position: relative;
  padding-left: 44px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  height: 34px;
}

.btn-social>:first-child {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 32px;
  height: 100%;
  line-height: 34px;
  font-size: 1.6em;
  text-align: center;
  border-right: 1px solid rgba(0,0,0,0.2);
}

.btn-google {
  color: #fff;
  border-color: rgba(0,0,0,0.2);
  background: rgb(66, 133, 244);
}
</style>
